@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:wght@100&family=Montserrat:wght@300&family=Roboto:wght@100;300;400;500;700;900&display=swap");

body {
  font-family: "Roboto", sans-serif;
}

.wrapper {
  max-width: 1040px;
  margin: auto;
}
.header {
  background-position: center top;
  height: 40vw;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
}
.header .title {
  display: flex;
  flex-direction: column;
  margin-top: 25vw;
}
.header .title h2 {
  color: #fff;
  font-weight: 400;
  line-height: 1;
  font-size: 1.2rem;
}
.header .title h2:before {
  content: "";
  background: #e71e34;
  width: 60px;
  height: 4px;
  display: block;
  margin-bottom: 0.5rem;
}
.header .title h3 {
  color: #fff;
  font-size: 3rem;
  line-height: 1;
  font-weight: 700;
}
@media screen and (max-width: 40em) {
  .header .title {
    margin-top: 20vw;
  }
  .header .title h2 {
    display: none;
  }
  .header .title h3 {
    font-size: 1.2rem;
  }
  .header .title h3::before {
    content: "";
    background: #e71e34;
    width: 60px;
    height: 4px;
    display: block;
    margin-bottom: 0.5rem;
  }
}

.miViajeHeader {
  background-image: url("../assets/miviaje.png");
}
.infoViajeHeader {
  background-image: url("../assets/infoviaje.png");
}
.itinerarioHeader {
  background-image: url("../assets/itinerario.png");
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-radius: 50;
  background-color: #d9d9d9;
  border-color: none;
}

.nav-link {
  color: black;
}

.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-link:hover {
  color: gray;
}

.responsiveTable tbody tr {
  border: 1px solid #eeeeee;
  padding: 0.25em;
}

.containerText h5 {
  color: white;
  font-weight: 300;
  margin-left: 10px;
  font-size: 1.3rem;
}

.containerText h1 {
  color: white;
  font-size: 5rem;
  font-weight: 900;
  line-height: 1;
}

.climaMexico {
  position: relative;
}

.climaMexico img {
  max-width: 478px;
}

.climaMexico div {
  background: rgb(231 30 52 / 90%);
  position: absolute;
  top: 110px;
  right: 120px;
  width: 60%;
  padding: 2rem 3rem;
  color: #fff;
}

.climaMexico div.mobile {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  margin: 2rem 0;
}

.climaMexico div span {
  font-weight: 400;
  font-size: 2rem;
}

.climaMexico div p,
.climaMexico div li {
  font-weight: 200;
  font-size: 1.1rem;
}

.lugaresCaminar h2 {
  font-size: 1.9rem;
  color: #333332;
  font-weight: 400;
  border-bottom: 1px solid #333332;
  margin-bottom: 2rem;
  padding-bottom: 0.5rem;
  width: 80%;
}

.lugaresCaminar .slick-slider {
  width: 95%;
}

.lugaresCaminar .slick-slider .slick-prev::before,
.lugaresCaminar .slick-slider .slick-next::before {
  background: url("../assets/slider_arrow.svg");
  opacity: 1;
  color: inherit;
  content: "";
  width: 40px;
  height: 40px;
  display: block;
  background-size: cover;
}

.lugaresCaminar .slick-slider .slick-prev {
  left: -10px;
  z-index: 10;
  width: 40px;
  height: 40px;
}

.lugaresCaminar .slick-slider .slick-prev::before {
  transform: rotate(180deg);
}

.lugaresCaminar .slick-slider .slick-next {
  right: -10px;
  width: 40px;
  height: 40px;
}

.lugaresCaminar .slick-slide img {
  width: 100%;
  padding: 0 1rem;
}

.lugaresCaminar p {
  text-align: center;
  font-size: 1.3rem;
  color: #7e7e7d;
  padding: 1rem 0 0;
  font-weight: 300;
  font-style: italic;
}

.lugaresTuristicos {
  background-color: #d9d9d9;
}

.lugaresTuristicos h2 {
  font-size: 1.9rem;
  color: #333332;
  font-weight: 400;
}

.lugaresTuristicos .slick-slider {
  width: 95%;
}

.lugaresTuristicos .slick-slider .slick-prev::before,
.lugaresTuristicos .slick-slider .slick-next::before {
  background: url("../assets/slider_arrow.svg");
  opacity: 1;
  color: inherit;
  content: "";
  width: 40px;
  height: 40px;
  display: block;
  background-size: cover;
}

.lugaresTuristicos .slick-slider .slick-prev {
  left: -10px;
  z-index: 10;
  width: 40px;
  height: 40px;
}

.lugaresTuristicos .slick-slider .slick-prev::before {
  transform: rotate(180deg);
}

.lugaresTuristicos .slick-slider .slick-next {
  right: -10px;
  width: 40px;
  height: 40px;
}

.lugaresTuristicos .slick-slide img {
  width: 100%;
  padding: 0 1rem;
}

.lugaresTuristicos h2 {
  font-size: 1.9rem;
  color: #333332;
  font-weight: 400;
}

.wework .slick-slider {
  border-radius: 0 50px 0 50px;
  overflow: hidden;
}

.wework .slick-slider .slick-prev::before,
.wework .slick-slider .slick-next::before {
  background: url("../assets/slider_arrow_trans.svg");
  opacity: 1;
  color: inherit;
  content: "";
  width: 40px;
  height: 40px;
  display: block;
  background-size: cover;
}

.wework .slick-slider .slick-prev {
  right: 15%;
  top: 85%;
  width: 40px;
  height: 40px;
  left: inherit;
  z-index: 1;
}

.wework .slick-slider .slick-prev::before {
  transform: rotate(180deg);
}

.wework .slick-slider .slick-next {
  right: 5%;
  top: 85%;
  width: 40px;
  height: 40px;
}

.wework h3 {
  border-bottom: 2px solid;
  color: #333332;
}

.wework .iconLocation {
  background: url("../assets/WeWork/location.svg") no-repeat left center;
  padding: 1rem 0px 1rem 3rem;
  margin-left: 1rem;
}

.wework span {
  margin: 1rem 0 0.5rem 1rem;
  font-size: 1.1rem;
  font-weight: 300;
  display: block;
}

.wework .iconMetro {
  background: url("../assets/WeWork/metro.svg") no-repeat 10px center;
  padding: 1rem 0px 1rem 4rem;
  margin-left: 1rem;
  font-weight: 200;
  font-size: 1.1rem;
  line-height: 1;
  margin-bottom: 1rem;
}

.wework .iconCar {
  background: url("../assets/WeWork/car.svg") no-repeat left center;
  padding: 1rem 0px 1rem 4rem;
  margin-left: 1rem;
  font-weight: 200;
  font-size: 1.1rem;
  line-height: 1;
  margin-bottom: 1rem;
}

.wework .iconMap {
  background: url("../assets/WeWork/map.svg") no-repeat 10px center;
  padding: 1rem 0px 1rem 4rem;
  margin-left: 1rem;
  font-weight: 200;
  font-size: 1.1rem;
  line-height: 1;
  margin-bottom: 1rem;
}

.infoRestaurantes a {
  color: #333332;
  text-decoration: none;
}

.infoRestaurantes ul {
  margin-bottom: 0px;
}

.infoRestaurantes li {
  padding-bottom: 0.5rem;
  font-weight: 300;
}

.apps h2 {
  font-size: 1.9rem;
  color: #333332;
  font-weight: 400;
}

.apps .logos img {
  padding: 0 1rem;
}

.mexicanoCercano {
  background: #eeeeee;
}

.mexicanoCercano h2 {
  font-size: 1.9rem;
  color: #333332;
  border-bottom: 2px solid #333;
  font-weight: 400;
}

.mexicanoCercano ul {
  margin-bottom: 0px;
}

.mexicanoCercano li {
  font-weight: bold;
  padding-bottom: 1rem;
}

.mexicanoCercano li a {
  color: #333;
  text-decoration: none;
  font-weight: 300;
}

.seguridad {
  border: 2px solid red;
  border-radius: 20px;
  max-width: 900px;
  margin: auto;
  padding-bottom: 3rem;
}

.seguridad::before {
  content: "";
  background: url("../assets/warning.png") no-repeat;
  width: 100px;
  height: 100px;
  display: block;
  background-size: contain;
  margin: auto;
  margin-top: -50px;
}

.seguridad h2 {
  text-align: center;
  margin: 3rem 0;
  font-size: 1.9rem;
  color: #333332;
  font-weight: 400;
}

.seguridad ul {
  margin-bottom: 0;
}

.seguridad li {
  padding-bottom: 0.5rem;
  font-weight: 300;
}

.alertViaje {
  font-size: 0.9rem;
}
.infoVuelo .vuelo {
  font-size: 1.3rem;
  margin-top: 1rem;
  line-height: 1;
}
.infoVuelo .hora {
  font-size: 2.1rem;
  line-height: 1;
}
.infoVuelo .codigo {
  line-height: 1;
}
.infoVuelo .ciudad {
  line-height: 1;
  margin-bottom: 1rem;
}
h5.tableTitle {
  margin: 1rem 0 0 3rem;
  border-bottom: 2px solid #a8a7a7;
  width: 65%;
}
.infoViaje .seguridad {
  padding: 0;
}
.infoViaje .seguridad p {
  font-size: 1.5rem;
  text-align: center;
  margin: 3rem 0;
}
.infoViaje .cards .content {
  border: 2px solid #333332;
  margin: 3rem 0;
  padding: 1rem 2rem;
  border-radius: 20px;
  text-align: center;
  min-height: 520px;
}
.infoViaje .cards .content h3 {
  font-size: 1.1rem;
  border-bottom: 2px solid #333332;
  margin: 1rem 0;
}
.infoViaje .cards .content ul {
  text-align: left;
}
.infoViajePaises {
  background: rgb(238, 238, 238);
}
.infoViajePaises h5 {
  font-size: 1.2rem;
  border-bottom: 2px solid #333332;
  margin-bottom: 1rem;
}
.infoViajePaises a {
  font-weight: bold;
  word-break: break-all;
}

@media screen and (max-width: 40em) {
  /*
    Force table elements to not behave like tables anymore
    Hide table headers (but not display: none;, for accessibility)
  */

  .responsiveTable table,
  .responsiveTable thead,
  .responsiveTable tbody,
  .responsiveTable th,
  .responsiveTable td,
  .responsiveTable tr {
    display: block;
    border: 1px solid #eeeeee;
  }

  .responsiveTable thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
    border-bottom: 2px solid #eeeeee;
  }

  .responsiveTable tbody tr {
    border: 1px solid #eeeeee;
    padding: 0.25em;
  }

  .responsiveTable td.pivoted {
    /* Behave like a "row" */
    border: none !important;
    position: relative;
    padding-left: calc(50% + 10px) !important;
    text-align: left !important;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }

  .responsiveTable td .tdBefore {
    /* Now like a table header */
    position: absolute;
    display: block;

    /* Top/left values mimic padding */
    left: 1rem;
    width: calc(50% - 20px);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    text-align: left !important;
    font-weight: 600;
  }

  .containerText {
    margin-left: 0px !important;
    text-align: center !important;
    flex-direction: column !important;
    margin-top: 50% !important;
  }

  #singInDiv {
    margin: auto;
    max-width: 280px;
  }
}

.slick-next:before,
.slick-prev:before {
  font-size: 30px;
  line-height: 1;
  opacity: 0.75;
  color: #e71e34;
}

.wework .iconCamaDoble {
  background: url("../assets/HotelInfo/cama-doble.svg") no-repeat left center;
  padding: 1rem 0px 1rem 4rem;
  margin-left: 1rem;
  font-weight: 200;
  font-size: 1.1rem;
  line-height: 1;
  margin-bottom: 1rem;
}

.wework .iconCamaSencilla {
  background: url("../assets/HotelInfo/cama-sencilla.svg") no-repeat left center;
  padding: 1rem 0px 1rem 4rem;
  margin-left: 1rem;
  font-weight: 200;
  font-size: 1.1rem;
  line-height: 1;
  margin-bottom: 1rem;
}

.wework .iconDoor {
  background: url("../assets/HotelInfo/door.svg") no-repeat left center;
  padding: 1rem 0px 1rem 4rem;
  margin-left: 1rem;
  font-weight: 200;
  font-size: 1.1rem;
  line-height: 1;
  margin-bottom: 1rem;
}

.wework .iconFood {
  background: url("../assets/HotelInfo/food.svg") no-repeat left center;
  padding: 1rem 0px 1rem 4rem;
  margin-left: 1rem;
  font-weight: 200;
  font-size: 1.1rem;
  line-height: 1;
  margin-bottom: 1rem;
}

.wework .iconPerson {
  background: url("../assets/HotelInfo/person.svg") no-repeat left center;
  padding: 1rem 0px 1rem 4rem;
  margin-left: 1rem;
  font-weight: 200;
  font-size: 1.1rem;
  line-height: 1;
  margin-bottom: 1rem;
}

/* Prueba */

@import url(https://fonts.googleapis.com/css?family=Raleway);
.snip1584 {
  font-family: "Monospace", sans-serif;
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 10px;
  min-width: 230px;
  max-width: 315px;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  text-align: left;
}
.snip1584 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}
.snip1584:before {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  top: 100%;
  content: "";
  background-color: rgba(51, 51, 51, 0.9);
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
}
.snip1584 img {
  vertical-align: top;
  max-width: 100%;
  backface-visibility: hidden;
}
.snip1584 figcaption {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.snip1584 h3,
.snip1584 h5 {
  margin: 0;
  opacity: 0;
  letter-spacing: 1px;
}
.snip1584 h3 {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  text-transform: uppercase;
  font-weight: 400;
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
  margin-bottom: 5px;
}
.snip1584 h5 {
  font-weight: normal;
  background-color: #ae895d;
  padding: 3px 10px;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.snip1584 a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.snip1584:hover:before,
.snip1584.hover:before {
  top: 10px;
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.snip1584:hover h3,
.snip1584.hover h3,
.snip1584:hover h5,
.snip1584.hover h5 {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}
.snip1584:hover h3,
.snip1584.hover h3 {
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.snip1584:hover h5,
.snip1584.hover h5 {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

/* Fin Prueba */

.navbar.scrolled {
  margin-top: 0 !important;
  padding-top: 20px;
}

.infoTabs .nav-item {
  font-weight: bold;
  font-size: 22px;
}

.infoVueloTitle {
  font-size: 25px;
  letter-spacing: 2px;
}

.infoVuelo div div {
  font-weight: bold;
  font-size: 22px;
}

.responsiveTable {
  width: 90% !important;
  margin: 0 auto;
}

.responsiveTable tbody tr:nth-of-type(odd) {
  background: rgb(238, 238, 238);
}

.responsiveTable th,
.responsiveTable td {
  padding: 10px;
}
