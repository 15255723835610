.color-nav {
  background-color: "rgba(52, 52, 52, 0.8)";
}
a.link {
  color: #333;
  text-decoration: none;
}
.navstick {
  position: fixed;
  top: 0;
}

.navlogo {
  position: fixed;
  z-index: 99;
}
.navigationTop.scrolled {
  background: rgb(0 0 0 / 70%);
  z-index: 10;
  padding-bottom: 1rem;
  transition: all 0.5s ease;
}
.navigationTop.scrolled .navbar-nav {
  margin-top: 10px;
  transition: all 0.5s ease;
}
.nav {
  border-bottom: 1px solid #ec1a1a;
}

.navbar-toggler {
  background-color: #e71e34;
}
